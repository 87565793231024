* {
    line-height: 1.3;
    margin: 0;
}

.no-scroll {
    overflow: hidden;
    position: fixed;
}

.fill {
    width: 100vw;
    height: 100vh;
    margin: 0px;
}

.fill-pct {
    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
}

.prevent-events {
    pointer-events: none;
}

.vertical-center {
    padding-top: 12em;
}

div .horizontal-center {
    text-align: center;
}

.title {
    font-size: 3em;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;
    color: #777;
}

.subtitle {
    display: inline-block;
    width: 60%;
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;
    color: #777;
}

.footer {
    text-align: center;
    pointer-events: none;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.heads-up-small {
    bottom: -5px;
    display: inline-block;
    width: 20%;
    min-width: 300px;
    border-radius: 75px 75px 0px 0px;
}

.list-inline {
    padding: 0px;
}

.list-inline>li {
    border-radius: 10%;
    width: 55px;
    line-height: 75px;
    height: 55px;
    display: inline-block;
    transition: all 1s;
    color: #888;
    font-size: 0.75em;
}

a {
    pointer-events: all;
}

a:link,
a:visited {
    color: inherit;
}

.list-inline>li:hover {
    transition: all 1s;
}

.icon-facebook:hover {
    color: white;
    background-color: #3b5998;
}

.icon-linkedin:hover {
    color: white;
    background-color: #0077B5;
}

.icon-github:hover {
    color: black;
}

@media screen and (max-width: 1024px) {
    * {
        line-height: 1.3;
        margin: 0;
    }

    .title {
        font-size: 4em;
    }

    .subtitle {
        font-size: 2.3em;
    }

    .list-inline>li {
        font-size: 1.7em;
        width: 100px;
        height: 100px;
        line-height: 135px;
    }

    .heads-up-small {
        min-width: 450px;
    }

    .vertical-center {
        padding-top: 20em;
    }
}
